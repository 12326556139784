.header {

    $h: &;
    @include trans;
    position: fixed;
    top: 0;
    width: 100%;
    background: $white;
    z-index: 1000;
    height: 60px;

    @include mq(md) {
        height: 90px;
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        left: 5%;
        top: 20px;
        z-index: 100;

        @include mq(md) {
            top: 30px;
        }

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &__logo {
        @include trans;

        height: 40px;
        width: 180px;
        position: absolute;
        top: 10px;
        z-index: 100;
        left:50px;
        margin: auto;

        @include mq(md) {
            top: 25px;
            left:0;
            right:0;
        }

        svg {
            fill: $darkestGray;
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height: 100%;
        }
    }

    &__social {
        position: absolute;
        right:5%;
        display: flex;
        top: 20px;

        @include mq(md) {
            top: 30px;
        }

        li {
            margin-left: 10px;

            @include mq(md) {
                margin-left: 30px;
            }
        }

        a {
            font-size: 21px;
        }
    }

    &__nav {
        @include trans;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        transform: translateX(-100%);
    }

    &__menu {
        @include trans;

        position: absolute;
        left: 0;
        top:0;
        bottom: 0;
        width: 100%;
        background: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 15px;

        @include mq(md) {
            padding-left: 15%;
        }

        li {
            margin-bottom: 20px;

            &:hover {

                .header__submenu {
                    z-index: 5;

                    img {
                        opacity: 1;
                    }
                }

                a {
                    font-weight: bold;

                    span:first-child {
                        opacity: 1;
                    }
                }
            }
        }

        a {
            font-size: 28px;
            display: block;

            &.-variant {
                font-size: 18px;
                padding-left: 30px;
                margin-bottom: 20px;

                @include mq(md) {
                    padding-left: 60px;
                    margin-bottom: 40px;
                }
            }

            span {
                &:first-child {
                    @include trans;
                    margin-right: 10px;
                    opacity: 0;

                    @include mq(md) {
                        margin-right: 30px;
                    }
                }
            }
        }
    }

    &__submenu {
        display: none;
        position: absolute;
        right: 0;
        top:0;
        bottom: 0;
        width: 30%;
        background: $green;
        justify-content: center;
        align-items: center;

        @include mq(md){
            display: flex;
        }

        img {
            @include trans;
            opacity: 0;
            width: 70%;
        }
    }

    &.-ontop {
        background: transparent;
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
